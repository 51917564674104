<template>
  <standard-layout>
    <template v-slot:heading><h1>Bitte bestätige Deine Reservierung</h1></template>
    <template v-slot:main>
      <div v-if="product">
        <div class="m-0 row bg-success text-light rounded p-1 link"
             @click="$router.replace({name: 'editReservation'})">
          <div class="col-11">
            <div>{{ product.name }}</div>
            <div>{{ reservation.startTime | date }} |
              {{ reservation.startTime.hour }}:00-{{ reservation.startTime.hour + product.duration }}:00
            </div>
            <div>{{ reservation.attendees }} Personen</div>
          </div>
          <div class="col-1 p-1 text-right h-100 justify-content-center">
            <font-awesome-icon icon="edit" size="1x"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12 h2 mt-3">Deine Kontaktdaten</div>
          <div class="col-12">
            <customer-editor v-model="customer"
                             submit-label="Jetzt Reservieren"
                             :fields="['firstName', 'lastName', 'email', 'phone', 'dp', 'agb']"
                             @save="submitConfirmation"/>
          </div>
        </div>
      </div>
    </template>
  </standard-layout>
</template>

<script>
import CustomerEditor from "@/components/checkout/CustomerEditor";
import {mapActions, mapGetters, mapMutations} from "vuex";
import store from '@/store'

export default {
  components: {CustomerEditor},
  data() {
    return {
      customer: {},
      dpAccepted: false,
    }
  },
  computed: {
    ...mapGetters(['reservation']),
    product() {
      return this.reservation.product
    }
  },
  methods: {
    ...mapActions(['confirmReservation', 'startLoading', 'stopLoading']),
    ...mapMutations(['updateReservation']),
    async submitConfirmation() {
      this.startLoading('einen Moment bitte ...')
      try {
        let reservation = this.reservation
        reservation.customer = this.customer
        await this.updateReservation(reservation)
        await this.confirmReservation()
            .then(() => {
              this.showInfo('Deine Reservierung  ' + this.reservation.number + ' ist abgeschlossen und wurde Dir zugesandt.')
              this.$router.push({
                name: 'viewReservation',
                params: {'number': this.reservation.number},
                query: {justConfirmed: 'true'}
              })
            })
      } catch (error) {
        this.handleError(error)
      } finally {
        this.stopLoading()
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!store?.getters.reservationDefined) {
      next({name: 'editReservation'})
    } else
      next((vm) => {
        vm.$gtag.event('reservation_intent', {})
      })
  }
}

</script>
